import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

export const ContactForm = () => {
    const form = useRef(); // useRef to handle form reference
    const [stateMessage, setStateMessage] = useState(null);
    const [formErrors, setFormErrors] = useState({}); // Track form validation errors

    // Validate email format using a regular expression
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    // Validate the form fields
    const validateForm = (data) => {
        let errors = {};
        if (!data.user_name) {
            errors.user_name = 'Name is required';
        }
        if (!data.user_email) {
            errors.user_email = 'Email is required';
        } else if (!validateEmail(data.user_email)) {
            errors.user_email = 'Invalid email format';
        }
        if (!data.message) {
            errors.message = 'Message is required';
        }
        return errors;
    };

    const sendEmail = (e) => {
        e.preventDefault();

        const formData = {
            user_name: e.target.user_name.value,
            user_email: e.target.user_email.value,
            message: e.target.message.value
        };

        const errors = validateForm(formData);
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors); // Set form errors if any

            // Get form's position relative to the top of the document
            const formTop = form.current.getBoundingClientRect().top + window.scrollY || window.pageYOffSet;

            // Scroll to the form position minus 60px offset
            window.scrollTo({
                top: formTop - 120, // Subtract 60px for the desired offset
                behavior: 'smooth'
            });

            return;
        }

        emailjs
            .sendForm('tycreate.app', 'tycreate.app.default', form.current, { publicKey: 'lvvXDdAQJu-HMnvYR' })
            .then(
                () => {
                    e.target.reset(); // Clear form fields
                    
                    // Get form's position relative to the top of the document
                    const formTop = form.current.getBoundingClientRect().top + window.scrollY || window.pageYOffSet;

                    // Scroll to the form position minus 60px offset
                    window.scrollTo({
                        top: formTop - 120, // Subtract 60px for the desired offset
                        behavior: 'smooth'
                    });

                    setStateMessage('Message sent!');
                    setFormErrors({});
                    setTimeout(() => {
                        setStateMessage(null);
                    }, 5000); // Hide message after 5 seconds
                    console.log('Email Sent!');
                },
                (error) => {
                    setStateMessage('Something went wrong, please try again later');
                    setTimeout(() => {
                        setStateMessage(null);
                    }, 5000); 
                    console.log('Failed to send email:', error.text);
                }
            );
    };

    return (
        <form ref={form} onSubmit={sendEmail}>
            {stateMessage && <p className="contact-form-state">{stateMessage}</p>}
            <label>Name</label>
            <input type="text" name="user_name" />
            {formErrors.user_name && <p className="error">{formErrors.user_name}</p>}

            <label>Email</label>
            <input type="email" name="user_email" />
            {formErrors.user_email && <p className="error">{formErrors.user_email}</p>}

            <label className="message-label">Message</label>{formErrors.message && <span className="error message">{formErrors.message}</span>}
            <textarea name="message" />

            <input type="submit" value="Send" />
        </form>
    );
};

export default ContactForm;
